<template>
  <div  class="ma-md-5 mb-16 px-0 " >


    <div class="d-flex">
      <v-btn icon small class="px-4 mt-6" to="/profile/menu" v-if="$vuetify.breakpoint.mobile">
        <v-icon size=20>mdi-arrow-left</v-icon>
      </v-btn>

      <div class="text-h6 font-weight-bold my-6">专题创作</div>
    </div>
    <form class="px-1">
      <ImagesUpload ref="images"></ImagesUpload>
      <v-text-field
        v-model="title"
        label="标题"
        
      ></v-text-field>
      <v-textarea
        label="正文"
        v-model="content"
      ></v-textarea>

      <div class="my-4">
        <v-btn @click="dialog=true" plain>
          <v-icon left>
            mdi-arrow-top-right-bottom-left
          </v-icon>
          相关的商品
        </v-btn>
        <v-chip-group
            active-class="primary--text"
            column
          >
          <v-chip
            v-for="goods in selectedGoods"
            :key="goods.goods_id"
            close
            @click:close="removegoods(goods.goods_id)"
          >
            {{ goods.goods_name }}
          </v-chip>
        </v-chip-group>

      </div>

      <v-btn
        dark
        class="mr-4"
        @click="submit"
      >
        保存
      </v-btn>
      <v-btn @click="clear">
        清除
      </v-btn>

    </form>


    <v-dialog
      v-model="dialog"
      content-class="winborder"
      :width="$vuetify.breakpoint.mobile?600:900"
    >

      <v-card class="pa-1 pa-md-4 pb-8">
        <v-btn  icon small class="favorite-icon mt-6 mr-0" fab absolute top right @click.stop="dialog=false" >
          <v-icon size=20>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          搜索相关商品
        </v-card-title>
        <v-card-text class=" px-1 pt-3 pb-0">
          <div class="d-flex mx-4">
            <v-text-field
              dense
              hide-details
              v-model="keyword"
              label="输入商品名称"
              clearable
            ></v-text-field>

            <v-btn icon small width=19 class="ml-1" @click="search">
              <img src="../assets/search_icon.png" class="mb-1" width=20 />
            </v-btn>
          </div>
          <div class="py-4 px-1 px-md-4">
            <v-item-group >
            <v-row>
              <v-col v-for="goods in goods_list" class="pa-1" :key="goods.goods_id" cols="6" md="4">
                <v-card
                  flat
                  :ripple=false
                  class="align-center goods-item pa-0 ma-2"
                  :class="{blackborder2:isselected(goods.goods_id)}"
                  @click="pick(goods)"
                >
                <v-img
                  :src="domain+goods.pic_cover_mid"
                  :aspect-ratio="3/4"
                  width=247
                  class=""
                />
                <div class="font-weight-black text-center text-subtitle-1 mb-0 mt-4">
                  {{goods.brand_name}}
                </div>
                <div class="font-weight-regular text-center  text-caption text-md-subtitle-2 mb-2">
                  {{goods.goods_name}}
                </div>

                </v-card>

              </v-col>
            </v-row>
            </v-item-group>
            <div  class="pa-5 text-center" v-if="showmore">
              <v-btn  dark outlined width="30%" tile class="main-btn" @click="pickgoods()">查看更多</v-btn>
            </div>
          </div>
        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>


import { mapState } from 'vuex'
import ImagesUpload from '@/components/ImagesUpload';
import topicApi from '@/api/topic'
import goodsApi from '@/api/goods'

export default {
  components: {
    ImagesUpload,
  },

  data: () => ({
    dialog: false,
    itemid : 0,
    iteminfo : null,
    title: '',
    content: '',
    keyword : "",
    goods_list: [],
    page_size:20,
    page:1,
    showmore : false,
    selectedGoods:[]

    

  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.imgdomain,
    }),
    invalid() {
      return this.content.length<5
    }
  },
  created() {


    
  },
  methods: {
    homesearch() {
      alert(3)
    },
    isselected(id){
      let flag = false
      for (let i=0; i<this.selectedGoods.length; i++)
      {
        if (this.selectedGoods[i].goods_id == id)
        {
          flag = true
        }
      }
      return flag
    },
    removegoods(id) {
      let removeflag = false
      for (let i=0; i<this.selectedGoods.length; i++)
      {
        if (this.selectedGoods[i].goods_id == id)
        {
          this.selectedGoods.splice(i,1)
          removeflag = true
        }
      }
      return removeflag
    },
    pick(item) {
      let removeflag = this.removegoods(item.goods_id)
      if (removeflag==false) {
        this.selectedGoods.push(item)
      }
    },
    submit() {
      if (this.content.length<10) {
        this.$message.warning("正文内容需要大于10个字符！")
        return
      }
      if (this.$refs.images.imglist.length==0) {
        this.$message.warning("至少需要上传一张图片！")
        return
      }
      //console.log(this.$refs.images.imglist)
      let images = []
      
      this.$refs.images.imglist.forEach(item => {
        
        images.push(item.path)
      })

      let goods_id = []
      this.selectedGoods.forEach(item => goods_id.push(item.goods_id))
      
      let params = {
        title: this.title,
        content: this.content,
        images,
        goods_id
      }
      topicApi.save(params).then(res => {
        console.log(res)
        if (res.code==1) {
          this.$message.success("保存成功!")
          this.$router.push("/profile/mytopic")
        } else {
          this.$message.warning("操作异常！")
        }
      })
    },
    clear() {
      this.$refs.images.imglist=[]
      this.title=''
      this.content=''
      this.selectedGoods = []
    },
    search(){
      this.goods_list = []
      this.page = 1
      this.pickgoods()
    },
    pickgoods() {

      let params = {page_size:this.page_size,page_index:this.page}
      if (this.keyword!="") params.kw = this.keyword

      goodsApi.goodslist(params).then((data) => {
        
        data.data.goods_list.data.forEach(item => this.goods_list.push(item))
        
        this.showmore = data.data.total_count > this.goods_list.length

        this.page++

      })

    }
    
  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  
.goods-item {
  cursor: pointer;
}

.blackborder2{
  border:2px solid black;
}


</style>

<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'

  @media #{map-get($display-breakpoints, 'md-and-up')}
    .categorybanner .v-image__image--cover
      background-position : center

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    .categorybanner .v-image__image--cover
      background-position : 17% !important
  
  .v-card.on-hover.theme--dark
    background-color: rgba(#FFF, 0.8)
    >.v-card__text
      color: #000000

</style>