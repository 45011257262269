<template>
  <div class="gxw-images-upload" >
    
    <v-item-group>
      <draggable v-model="imglist" class="d-flex  flex-wrap" draggable=".pointer">
        <v-item class="pa-0 mr-18 mb-4 image-item pointer" v-for="img,j in imglist" :key="img.path" >
          <div>
          <v-img :src="domain + img.path" max-width="190" max-height="190" contain>
            <v-btn  icon small class="btn-icon " fab absolute top right @click.stop="remove(j)" >
              <v-icon size=20>mdi-close</v-icon>
            </v-btn>
          </v-img></div>
          
        </v-item>
        
        <v-item class="image-item add-btn mb-4 ml-md-8 ma-0 pa-0 d-flex justify-center"  >
          <div class="" @click="handleUpload">
            <v-icon x-large>mdi-plus</v-icon>
          </div>
        </v-item>
      </draggable>
    </v-item-group>
   
    <input
      ref="uploader"
      class="d-none"
      type="file"
      multiple
      accept="image/*"
      @change="onFileChanged"
    />

    <v-dialog
      v-model="dialog"
      width="300"
    >
      <v-card
        color="primary"
        dark
        class="ma-0 pa-0"
      >
        <v-card-text>
          正在上传图片...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import topicApi from '@/api/topic'
import { mapState } from 'vuex'

export default {
  name: 'ImagesUpload',
  props: {
    msg: String
  },
  components: {
      draggable,
  },
  computed: {
    ...mapState({
      domain: state => state.app.imgdomain,
    }),
  },
  data() {
    return {
      dialog:false,
      imglist: []
    }
  },
  methods: {
    handleUpload() {
      console.log(434)
      // Trigger click on the FileInput
      this.$refs.uploader.click();
      console.log(43)
    },
    remove(i) {
      this.imglist.splice(i,1)
    },
    onFileChanged(e) {
      if (e.target.files.length==0) {
        return
      }
      this.dialog = true
      let counter = 0
      console.log(e.target.files)
      for (let i=0; i<e.target.files.length; i++) {
        let file = e.target.files[i]
        topicApi.uploadimages(file).then(res => {
          res = res.data
          if (res.code==1) {
            res.data.forEach(item => this.imglist.push(item))

          }
          counter++ 
          if (counter==e.target.files.length)
          {
            this.dialog = false
          }
          
        })
      }
      

    }
  }
}
</script>


<style scoped>
.add-btn{
  border:1px solid black;
  cursor: pointer;
}
.image-item{
  width:190px;
  height:190px;
}
.btn-icon{
  margin-top: 8px;
  margin-right: -30px;
}
</style>
